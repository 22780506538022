import axios from "axios";
import { API_CONFIG } from "../../config/main";
import ResetToken from "../../utils/ResetToken";

const BASE_URL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}`;
const DRIVESHOP_BASE = `${API_CONFIG.DRIVESHOP_URL}${API_CONFIG.BASE_PATH}`;

export function fetchActivities(params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.GET_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        ResetToken();
      }
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchPaymentMethods(params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.PAYMENT.GET_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Payment data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        ResetToken();
      }
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchFeeTypes(params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.FEE_TYPES.GET_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Fee types data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        ResetToken();
      }
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchDrivers(id, params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.DRIVERS.GET_ALL(id)}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Drivers data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchActivityReceipts(params = {}) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.RECEIPTS.GET_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities receipts fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchUploadedReceipts(params = {}) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.RECEIPTS.UPLOAD()}`;
  return axios
    .get(apiURL, {
      params: params,
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities receipts fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchACtivityAttachments(params = {}) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.ATTACHMENT.GET_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities attachment fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function updateActivity(data = {}, id) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.UPDATE(id)}`;
  return axios
    .put(apiURL, data)
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities updated successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function uploadReceipt(data = {}, { setUploadProgress } = {}) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.RECEIPTS.UPLOAD()}`;
  return axios
    .post(apiURL, data, {
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
      onUploadProgress: (progressEvent) => {
        const fileSize = data.get("uploaded_data").size;
        const progress = Math.floor((progressEvent.loaded / fileSize) * 100);
        setUploadProgress(progress);
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities receipts uploaded successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function uploadAttachment(data = {}, { setUploadProgress } = {}) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.ATTACHMENT.UPLOAD()}`;
  return axios
    .post(apiURL, data, {
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
      onUploadProgress: (progressEvent) => {
        const fileSize = data.get("uploaded_data").size;
        let progress = Math.floor((progressEvent.loaded / fileSize) * 100);
        if (progress > 100) progress = 100
        setUploadProgress(progress);
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities attachment uploaded successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function updateExpense(data = {}, activityId, costId) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.EXPENSE.UPDATE(
    activityId,
    costId
  )}`;
  return axios
    .put(apiURL, { activity_item_cost: data })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Cost expense details updated successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function deleteExpense(activityId, costId) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.EXPENSE.DELETE(
    activityId,
    costId
  )}`;
  return axios
    .delete(apiURL)
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Cost expense details deleted successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function createExpense(data = {}, activityId) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.EXPENSE.CREATE(
    activityId
  )}`;
  return axios
    .post(apiURL, data)
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities expense created successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function deleteReceipts(id) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.RECEIPTS.DELETE(
    id
  )}`;
  return axios
    .delete(apiURL, {
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities receipts deleted successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function deleteProcessingReceipts(id) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.RECEIPTS.DELETE_UPLOAD(
    id
  )}`;
  return axios
    .delete(apiURL, {
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities receipts deleted successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function deleteAttachment(id) {
  const apiURL = `${DRIVESHOP_BASE}${API_CONFIG.END_POINTS.ACTIVITY.ATTACHMENT.DELETE(
    id
  )}`;
  return axios
    .delete(apiURL, {
      headers: {
        Authorization: `Token ${localStorage.fmsToken}`,
      },
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activities attachment deleted successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchActivityData(id, params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.FETCH_DETAILS(
    id
  )}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Activity data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchExtraPreps(params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.PREP_TYPES.FETCH_ALL()}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Preps fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchOffices() {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.GET_ALL()}`;
  return axios
    .get(apiURL)
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Offices fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}

export function fetchExpenseDetails(id, params = {}) {
  const apiURL = `${BASE_URL}${API_CONFIG.END_POINTS.ACTIVITY.EXPENSE.FETCH_ONE(
    id
  )}`;
  return axios
    .get(apiURL, {
      params: params,
    })
    .then((res) => {
      const success = res.status === 200;
      return {
        success: success,
        message: "Fee types data fetched successfully.",
        data: res.data,
      };
    })
    .catch((err) => {
      const errMsg = err.response.data.errors
        ? err.response.data.errors.map((a) => a.detail).join(",")
        : "Something went wrong.";
      throw new Error(errMsg);
    });
}
